<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#005f32"
      spinner="bar-fade-scale" />
    <v-snackbar v-model="showsnackbar" color="#005f32" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">TARGET DETAILS</span>
          <v-spacer></v-spacer>
          <v-flex xs12 align-self-center pt-2>
            <v-layout wrap justify-start>
              <v-flex xs12 text-left>
                <span style="font-family: kumbhRegular; font-size: 14px">*Select the options to filter the
                  results</span>
              </v-flex>
              <v-flex xl3 lg3 md3 sm6 xs12 pr-2>
                <v-select clearable v-model="financialYear" :items="financialYears" item-text="name" item-value="name"
                  label="Financial Year" required outlined dense hide-details></v-select>
              </v-flex>
              <v-flex xl3 lg3 md3 sm6 xs12 pr-2 pt-2 pt-lg-0 pt-sm-0 pt-md-0>
                <v-text-field v-model="keyword" outlined dense hide-details clearable label="Search Employee">
                </v-text-field>

              </v-flex>
            </v-layout>
          </v-flex>
        </v-card-title>
        <v-layout wrap justify-start px-4 v-if="data.length > 0">
          <v-flex xs12 pt-3>
            <v-simple-table class="align-center">
              <template v-slot:default>
                <thead style="background-color: #005f32">
                  <tr>

                    <th style="
                          font-size: 15px;
                          color: #ffffff;
                          font-family: kumbhRegular;
                        " class="text-left">
                      Employee Id
                    </th>
                    <th style="
                    font-size: 15px;
                    color: #ffffff;
                    font-family: kumbhRegular;
                  
                  " class="text-left">
                      name

                    </th>
                    <th style="
                 font-size: 15px;
                 color: #ffffff;
                 font-family: kumbhRegular;
               " class="text-left">
                      Met Count


                    </th>

                    <th style="
                        font-size: 15px;
                        color: #ffffff;
                        font-family: kumbhRegular;
                      " class="text-left">
                      Not Met Count

                    </th>




                    <th style="
              font-size: 15px;
              color: #ffffff;
              font-family: kumbhRegular;
            " class="text-left">
                      Delayed Met Count

                    </th>
                    <th style="
              font-size: 15px;
              color: #ffffff;
              font-family: kumbhRegular;
            " class="text-left">
                      Total Score
                    </th>
                    <th style="
              font-size: 15px;
              color: #ffffff;
              font-family: kumbhRegular;
            " class="text-left">
                      Total Weightage

                    </th>
                  </tr>
                </thead>
                <tbody class="align-center">
                  <template>
                    <tr class=" text-left align-center" style="cursor:pointer;" v-for="(item) in data" :key="item._id"
                      @click="redirectToViewPage(item)">

                      <td style="text-align:justify">
                        <span v-if="item.employeeId" class="" style="font-family: kumbhMedium; cursor: pointer">
                          {{ item.employeeId }}
                        </span>
                      </td>

                      <td>
                        <span v-if="item.name" class="" style="font-family: kumbhMedium">{{
                          item.name }}</span>
                      </td>

                      <td>
                        <span class="" style="font-family: kumbhMedium">{{ item.metCount }}</span>
                      </td>

                      <td>
                        <span class="" style="font-family: kumbhMedium">{{ item.notMetCount }}</span>
                      </td>
                      <td>
                        <span class="" style="font-family: kumbhMedium">{{ item.delayedMetCount }}</span>
                      </td>
                      <td>
                        <span class="" style="font-family: kumbhMedium">{{ item.totalScore }}</span>
                      </td>
                      <td>
                        <span class="" style="font-family: kumbhMedium">{{ item.totalWeightage }}</span>
                      </td>

                    </tr>
                  </template>
                </tbody>
              </template>
            </v-simple-table>
          </v-flex>
        </v-layout>
        <v-layout wrap v-else>
          <v-flex xs12 align-self-center pa-16>
            <span class="kumbhRegular">No Data found!</span>
          </v-flex>
        </v-layout>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center v-if="pages > 1">
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination prev-icon="mdi-menu-left" next-icon="mdi-menu-right" :length="pages" :total-visible="7"
            v-model="currentPage" color="#005f32"></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data: () => ({
    keyword: "",
    showsnackbar: false,
    ServerError: false,
    msg: null,
    appLoading: false,
    g: false,
    financialYear: "",
    financialYears: [],
    rules: {
      required: (value) => !!value || "Required.",
    },
    data: [],
    pages: 0,
    currentPage: 1,
    totalData: 0,
  }),

  mounted() {
    this.getData();
    this.generateFinancialYears();
  },
  watch: {
    currentPage() {
      this.getData();
    },
    keyword() {
      this.getData();
      this.currentPage = 1
    },
    financialYear() {
      this.getData();
      this.currentPage = 1
    },
  },
  methods: {
    redirectToViewPage(item) {
      this.$router.push(
        `/Targets/teamTarget?employeeId=${item._id}`
      );
    },
    generateFinancialYears() {
      const startYear = 2000;
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth();

      // If the current month is before April (0-based index), it's still the previous financial year
      const endYear = currentMonth < 3 ? currentYear : currentYear + 1;

      for (let year = startYear; year <= endYear; year++) {
        this.financialYears.push(`${year}-${year + 1}`);
      }

      // Set default financial year to the current financial year
      const defaultStartYear = currentMonth < 3 ? currentYear - 1 : currentYear;
      this.financialYear = `${defaultStartYear}-${defaultStartYear + 1}`;
    },
    getData() {
      this.appLoading = true;
      const financialYearToPass = this.financialYear;
      axios({
        url: "/admin/reportingmanagers/employees/list/v1",
        method: "GET",
        params: {
          keyword: this.keyword,
          financialYear: financialYearToPass,
          page: this.currentPage,
          limit: 20,

        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.data = response.data.data;
          this.totalData = response.data.total;
          this.pages = Math.ceil(this.totalData / response.data.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style>
.tableHead {
  font-size: 15px;
  color: #ffffff;
  font-family: kumbhMedium;
}
</style>